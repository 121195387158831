import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Link from 'components/Link';
import MainHero from 'components/MainHero';
import SideBox2 from 'components/videoproc/SideBox2';
// import SideBox3 from 'components/videoproc/SideBox3';
import Divider from 'components/Divider';
// import ImageCTA from 'components/videoproc/ImageCTA';
// import FeaturesList from 'components/videoproc/FeaturesList';
import OrderedListSection from 'components/OrderedListSection';
import { CURRENT_YEAR } from 'config';

export default function DownloaderPage({ pageContext }) {
  // Dynamic site downloader page (See: data/site.json)
  const { site = { content: 'video', videoproc: true } } = pageContext;
  const isSitePage = !!site.key;

  const { t } = useTranslation();

  const siteContent = isSitePage ? t(`common:${site.content}`) : null;
  const siteDesc = isSitePage
    ? t('downloader:meta.site_desc', {
        site: site.name,
        // Use "Video and audio" for sites with "video" content
        content:
          site.content === 'video' ? t('common:video_audio') : siteContent,
      })
    : null;

  return (
    <>
      <SEO
        title={
          isSitePage
            ? t('downloader:meta.site_title', {
                site: site.name,
                content: siteContent,
                info: `[${t('common:updated_year', { year: CURRENT_YEAR })}]`,
              })
            : t('downloader:meta.title', {
                info: `[${t('common:updated_year', { year: CURRENT_YEAR })}]`,
              })
        }
        description={siteDesc ?? t('downloader:meta.desc')}
        keywords={
          isSitePage
            ? t('downloader:meta.site_keywords', {
                site: site.name,
                content: siteContent,
              })
            : t('downloader:meta.keywords')
        }
      />

      <MainHero
        title={
          isSitePage ? (
            <Trans
              i18nKey="downloader:site_dl"
              values={{ site: site.name, content: siteContent }}
              components={[<span className="text-blue-500" />]}
            />
          ) : (
            <Trans
              i18nKey="common:online_dl"
              components={[<span className="text-blue-500" />]}
            />
          )
        }
        description={
          siteDesc ?? (
            <Trans
              i18nKey="downloader:main_desc"
              components={[<Link href="/sites" className="underline" />]}
            />
          )
        }
        aside={
          <div>
            <div className="mb-6">
              <SideBox2 siteKey={site.key} />
            </div>
            {/* <SideBox3 /> */}
          </div>
        }
      />

      {/* <ImageCTA /> */}

      {/* <Divider /> */}

      {/* <FeaturesList /> */}

      <Divider />

      <OrderedListSection
        title={t('downloader:how.title')}
        subtitle={t('downloader:how.desc')}
        list={[
          t('downloader:how.step1'),
          t('downloader:how.step2'),
          <Trans
            i18nKey="downloader:how.step3"
            values={{ button: t('common:start') }}
            components={[<strong className="font-bold" />]}
          />,
          t('downloader:how.step4'),
        ]}
      />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "downloader", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
