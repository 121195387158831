import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import { trackPromotion } from 'lib/analytics';
// import Link from 'components/Link';
import AppDownload2 from 'components/videoproc/AppDownload2';
import { vimeoLink, getAppLink, getAILink } from 'lib/videoproc';

export default function SideBox2({ siteKey }) {
  const { t, i18n } = useTranslation();

  const isVimeo = siteKey === 'vimeo';

  return (
    <AppDownload2
      title={
        <Trans
          i18nKey="videoproc:sb2_title"
          components={[<span style={{ color: '#ff6600' }} />]}
        />
      }
      list={[
        <Trans
          i18nKey="videoproc:sb2_1"
          components={[<strong className="font-bold" />]}
        />,
        <Trans
          i18nKey="videoproc:sb2_2"
          components={[<strong className="font-bold" />]}
        />,
        <Trans
          i18nKey="videoproc:sb2_3"
          components={[
            <a
              href="https://www.videoproc.com/video-converting-software/feature-video-compressing.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-compress&ttref=stvideo-home-compress"
              target="_blank"
              rel="nofollow noreferrer noopener"
              className="text-blue-500 underline"
              onClick={() => trackPromotion('link-compress')}
            />,
            <a
              href={getAILink(i18n.language)}
              target="_blank"
              rel="nofollow noreferrer noopener"
              className="text-blue-500 underline"
              onClick={() => trackPromotion('link-enhance')}
            />,
          ]}
        />,
        // t('videoproc:sb2_3'),
      ]}
      btnText={isVimeo ? t('videoproc:dl2vimeo') : t('videoproc:dl2')}
      btnLink={isVimeo ? vimeoLink : getAppLink(i18n.language)}
      // footer={
      //   <span className="text-gray-600">
      //     <Trans
      //       i18nKey="videoproc:aside_footer"
      //       components={[
      //         <Link
      //           href="/videoproc"
      //           rel="nofollow"
      //           className="font-bold text-gray-900 underline"
      //           onClick={() => trackPromotion('txt-heres-why')}
      //         />,
      //       ]}
      //     />
      //   </span>
      // }
    />
  );
}
